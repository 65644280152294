.wraper{
    width: 35%;
    margin: auto;
    margin-top: 5%;
    border: 1px solid gray;
    border-radius: 0.5rem;
}
.errorBorder{
    border-color: red!important;
}
.errorText{
    color: red!important;
}
@media(max-width:992px){
    .wraper{
        width: 45%;
    }
}