.searchInput{
    box-shadow: none!important;
    border-left: none!important;
    z-index: 0!important;
}
.searchIcon{
    cursor: pointer;
}
.row{
    border-top: 0.1rem solid rgb(192, 188, 188)!important; 
    border-bottom: 0.1rem solid rgb(192, 188, 188)!important;
    font-family: 'Mulish', sans-serif;
    font-size: medium;
   
}

.tdPadding{
    padding-top: 5px!important;
    padding-bottom: 5px!important;
}
.borderTop{
    border-top: 3px solid gainsboro;
}