.searchIcon{
    cursor: pointer;
}
.searchInput{
    box-shadow: none!important;
    border-left: none!important;
    z-index: 0!important;
    outline: none!important;
}
.btn{
    background-color: #FF7F00!important;
    border: none!important;
}
.btn:hover{
    background-color: #ff4800!important;
}
.bottomBorder{
    border-bottom: 4px solid rgb(203, 202, 202);
}
.header{
    border-bottom: 0.1rem solid rgb(192, 188, 188)!important;
}
.row{
    border-top: 0.1rem solid rgb(192, 188, 188)!important; 
    border-bottom: 0.1rem solid rgb(192, 188, 188)!important;
    font-family: 'Mulish', sans-serif;
    font-weight: bold;
    font-size: small;
}
.dropdownBg{
    background-color: rgb(205, 210, 214)!important;
}
.dropdownItem:hover{
 background-color: #fff!important;
 color: #ff4800!important;
}
