.greenBg{
    width: 2rem;
    height: 1rem;
    background-color: #84DB3A;
}
.yellowBg{
    width: 2rem;
    height: 1rem;
    background-color: #FF7E00;
}
.whiteBg{
    width: 2rem;
    height: 1rem;
    background-color: #FFFFFF;
}