.spinerContainer{
    min-width: 100%;
    min-height: 100vh;
    position: absolute;
    top: 0%;
    left: 0%;
    right: 0%;
    bottom: 0%;
    background-color: hsl(210, 14%, 97%);
    padding-top: 10%;
    z-index: 100;
}
.spiner{
    margin: auto!important;
}